import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { gsap } from 'gsap';
import { useHistory } from 'react-router-dom';

const Navbar = () => {
  const [dropdown, setDropdown] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (dropdown) {
      gsap.fromTo(
        `.dropdown-menu.${dropdown}`,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, duration: 0.5 },
      );
    }
  }, [dropdown]);

  const handleMouseEnter = (menu) => {
    if (dropdown === menu) {
      setDropdown(null);
      return;
    }
    setDropdown(menu);
  };

  const [isCross, setIsCross] = useState(false);

  const handleClick = () => {
    setIsCross(!isCross);
  };

  const handleSignIn = () => {
    history.push('/auth/sign-in');
  };
  
  const handleBookADemo = (event) => {
    event.preventDefault();
    window.open('https://calendly.com/ressi-54/30min?back=1', '_blank');
  };

  useEffect(() => {
    gsap.fromTo(
      '.signin-button',
      { autoAlpha: 0, y: -20 },
      { autoAlpha: 1, y: 0, duration: 0.5, delay: 0.5 },
    );
    gsap.fromTo(
      '.demo-button',
      { autoAlpha: 0, y: -20 },
      { autoAlpha: 1, y: 0, duration: 0.5, delay: 0.5 },
    );
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="/pixolabs-logo.png" alt="Logo" />
      </div>
      <div className="button-and-icon-container">
        <button className="signin-button" onClick={handleSignIn}>
          <span>Sign In</span>
        </button>
        <button className="demo-button" onClick={handleBookADemo}>
          <span>Book a Demo</span>
          <div className="arrow-icons-container">
            <span className="arrow-icon">
              <svg
                className="previous-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 20 21"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
                aria-hidden="true"
                role="img"
              >
                <path
                  d="M14.3828 7.82861L17.5078 10.9536M17.5078 10.9536L14.3828 14.0786M17.5078 10.9536L2.50781 10.9536"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <svg
                className="next-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 20 21"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
                aria-hidden="true"
                role="img"
              >
                <path
                  d="M14.3828 7.82861L17.5078 10.9536M17.5078 10.9536L14.3828 14.0786M17.5078 10.9536L2.50781 10.9536"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
          </div>
        </button>
        <div className="small-navbar-icon-container" onClick={handleClick}>
          <div
            className={`small-navbar-icon ${
              isCross ? 'small-navbar-cross-icon' : 'small-navbar-bar-icon'
            }`}
          >
            <div className="small-navbar-bar small-navbar-bar1"></div>
            <div className="small-navbar-bar small-navbar-bar2"></div>
            <div className="small-navbar-bar small-navbar-bar3"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
