import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { gapi } from 'gapi-script';
import { GoogleLogin } from '@react-oauth/google';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/mailamp.webp';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { signIn } from 'library/lib';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { signUp } from 'library/lib';
import NewFaceBookLoginPage from 'views/admin/facebook-login/NewFaceBookLoginPage';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );
  const [show, setShow] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();
  const [signUpFlag, setSignUpFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({
        clientId:
          '190854580021-mr130175ei6mvh9vh34p89j0ia29p05v.apps.googleusercontent.com',
      });
    });
  });

  const handleAuthAction = async () => {
    setLoading(true);
    try {
      if (signUpFlag) {
        await signUp(email, password);
        setSignUpFlag(false); // Reset to sign in mode after signing up
        toast.success('Account created successfully!');
      } else {
        const userData = await signIn(email, password);
        console.log('🚀 ~ handleAuthAction ~ userData:', userData);
        if (userData) {
          localStorage.setItem('userEmail', userData.email);
          history.push({
            pathname: '/admin/connect-facebook',
            state: { email: email },
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Authentication failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const responseGoogle = (response) => {
    if (response.credential) {
      fetch(
        'https://sms-app-backend-lwl0.onrender.com/api/v1/login/google-login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ credential: response.credential }),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setLoggedIn(true);
          history.push('/admin/property-listings');
          console.log(data);
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    } else {
      console.log('Failed to log in.');
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        // px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '3rem' }}
        flexDirection="column"
        backgroundColor="white"
        boxShadow="lg"
        rounded="lg"
        px="6"
        py="8"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {signUpFlag ? 'Create Account' : 'Sign In'}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {signUpFlag
              ? 'Enter your email and password to create an account!'
              : 'Enter your email and password to sign in!'}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}
          {/* ******** Google Login commented  *********** */}
          {/* {loggedIn ? (
            <Button
              fontSize="sm"
              me="0px"
              mb="26px"
              py="15px"
              h="50px"
              borderRadius="16px"
              bg={googleBg}
              color={googleText}
              fontWeight="500"
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
            >
              <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
              Logged in
            </Button>
          ) : (
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(
                  '🚀 ~ SignIn ~ credentialResponse:',
                  credentialResponse,
                );
                responseGoogle(credentialResponse);
                history.push('/admin/llm-generation');
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          )} */}

          {/* ********* Add Facebook Login here and uncomment below code ********* */}
          {/* <Flex align="center" mb="25px" mt="25">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="abc@gmail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {/* <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={() => handleAuthAction()}
              isLoading={loading}
              spinner={<Spinner size="sm" />}
              isDisabled={loading}
            >
              {signUpFlag ? 'Create Account' : 'Sign In'}
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {signUpFlag ? 'Already have an account?' : 'Not registered yet?'}
              <Text
                color={textColorBrand}
                as="span"
                ms="5px"
                fontWeight="500"
                cursor={'pointer'}
                onClick={() => setSignUpFlag(!signUpFlag)}
              >
                {signUpFlag ? 'Sign In' : 'Create an Account'}
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
