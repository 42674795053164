import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdCampaign,
  MdEmail,
  MdCode,
  MdWhatshot,
  MdChat,
  MdStar,
  MdFlare,
  MdEvent,
  MdGroups,
} from 'react-icons/md';
import { IoIosMan, IoIosSend } from 'react-icons/io';
import { RiDashboardFill } from 'react-icons/ri';
import { GiJourney } from 'react-icons/gi';
import { FaProductHunt, FaGoogle, FaInbox, FaFacebook } from 'react-icons/fa';
import { RiChatFollowUpFill } from 'react-icons/ri';
import { LuListTodo } from 'react-icons/lu';
// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';
import CampaignDashboard from 'views/CampaignDashboard/CampaignDashboard';
// Auth Imports
import SignInCentered from 'views/auth/signIn';
import ScheduleCalendar from 'views/admin/schedule-calendar/ScheduleCalendar';
import LlmCodeGeneration from 'views/LLM-Code-Generation/LlmCodeGeneration';
import CampaignDetails from 'views/CampaignDashboard/CampaignDetails';
import Stripo from 'views/admin/stripo/Stripo';
import Journey from 'views/Journey/Journey';
import BookMeeting from 'views/admin/book-meeting/BookMeeting';
import ProductHunt from 'views/admin/product-hunt/ProductHunt';
import GoogleLoginBox from 'views/admin/EmailFiltering/GoogleLogin';
import EmailFiltering from 'views/admin/EmailFiltering/EmailFiltering';
import PropertyListingsForm from 'views/admin/send-sms/PropertyListings';
import CreateAgent from 'views/admin/send-sms/AgentCreation';
import FollowUp from 'views/admin/send-sms/FollowUp';
import Dashboard from 'views/admin/send-sms/Dashboard';
import WhatsAppMessageForm from 'views/admin/send-sms/WhatsAppMessageForm';
import FacebookPage from 'views/admin/send-sms/FacebookPage';
import LandingPage from 'views/admin/LandingPage/LandingPage';
import Todos from 'views/admin/send-sms/Todos';
import ChatInbox from 'views/admin/send-sms/ChatInbox';
const routes = [
  // {
  //   name: 'Main Dashboard',
  //   layout: '/admin',
  //   path: '/default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: MainDashboard,
  // },
  // {
  //   name: 'NFT Marketplace',
  //   layout: '/admin',
  //   path: '/nft-marketplace',
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: 'Data Tables',
  //   layout: '/admin',
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: '/data-tables',
  //   component: DataTables,
  // },
  // {
  //   name: 'Profile',
  //   layout: '/admin',
  //   path: '/profile',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: 'Connect Meta Business Account',
    layout: '/admin',
    path: '/connect-facebook',
    icon: <Icon as={FaFacebook} width="20px" height="20px" color="inherit" />,
    component: FacebookPage,
  },
  {
    name: 'Property Listings',
    layout: '/admin',
    path: '/property-listings',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: PropertyListingsForm,
  },
  {
    name: 'Create Agent',
    layout: '/admin',
    path: '/create-agent',
    icon: <Icon as={IoIosMan} width="20px" height="20px" color="inherit" />,
    component: CreateAgent,
  },
  {
    name: 'Follow Up',
    layout: '/admin',
    path: '/follow-up',
    icon: (
      <Icon
        as={RiChatFollowUpFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: FollowUp,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboard',
    icon: (
      <Icon as={RiDashboardFill} width="20px" height="20px" color="inherit" />
    ),
    component: Dashboard,
  },

  {
    name: 'Send Messages',
    layout: '/admin',
    path: '/send-messages',
    icon: <Icon as={IoIosSend} width="20px" height="20px" color="inherit" />,
    component: WhatsAppMessageForm,
  },
  {
    name: 'Your Inbox',
    layout: '/admin',
    path: '/your-inbox',
    icon: <Icon as={FaInbox} width="20px" height="20px" color="inherit" />,
    component: ChatInbox,
  },
  {
    name: 'Landing Page',
    layout: '/auth',
    path: '/landing-page',
    icon: <Icon as={FaInbox} width="20px" height="20px" color="inherit" />,
    component: LandingPage,
  },
  {
    name: 'Todos',
    layout: '/admin',
    path: '/todos',
    icon: <Icon as={LuListTodo} width="20px" height="20px" color="inherit" />,
    component: Todos,
  },

  // {
  //   name: 'Schedule Calendar',
  //   layout: '/admin',
  //   path: '/schedule-calendar',
  //   icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
  //   component: ScheduleCalendar,
  // },
  // {
  //   name: 'Template Generator',
  //   layout: '/admin',
  //   path: '/llm-generation',
  //   icon: <Icon as={MdFlare} width="20px" height="20px" color="inherit" />,
  //   component: LlmCodeGeneration,
  // },
  // {
  //   name: 'Campaign Dashboard',
  //   layout: '/admin',
  //   path: '/campaign-dashboard',
  //   icon: <Icon as={MdCampaign} width="20px" height="20px" color="inherit" />,
  //   component: CampaignDashboard,
  // },
  // {
  //   name: 'Campaign Details',
  //   layout: '/admin',
  //   path: '/campaign-details/:campaign_id',
  //   icon: <Icon as={MdCampaign} width="20px" height="20px" color="inherit" />,
  //   component: CampaignDetails,
  // },
  // {
  //   name: 'Stripo',
  //   layout: '/admin',
  //   path: '/stripo',
  //   icon: <Icon as={MdEvent} width="20px" height="20px" color="inherit" />,
  //   component: Stripo,
  // },
  // {
  //   name: 'Book Meeting',
  //   layout: '/admin',
  //   path: '/book-meeting',
  //   icon: <Icon as={MdGroups} width="20px" height="20px" color="inherit" />,
  //   component: BookMeeting,
  // },
  // {
  //   name: 'Product Hunt',
  //   layout: '/admin',
  //   path: '/product-hunt',
  //   icon: (
  //     <Icon as={FaProductHunt} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: ProductHunt,
  // },
  // {
  //   name: 'Google Login',
  //   layout: '/admin',
  //   path: '/google-login',
  //   icon: <Icon as={FaGoogle} width="20px" height="20px" color="inherit" />,
  //   component: GoogleLoginBox,
  // },
  // {
  //   name: 'Email Filtering',
  //   layout: '/admin',
  //   path: '/email-filtering',
  //   icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
  //   component: EmailFiltering,
  // },
  // {
  //   name: 'Start your journey',
  //   layout: '/admin',
  //   path: '/start-your-journey',
  //   icon: <Icon as={GiJourney} width="20px" height="20px" color="inherit" />,
  //   component: Journey,
  // },

  // {
  //   name: 'RTL Admin',
  //   layout: '/rtl',
  //   path: '/rtl-default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: RTL,
  // },
];

export default routes;
