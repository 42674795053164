import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Select,
  Text,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

const FollowUp = () => {
  const [condition, setCondition] = useState('');
  const [thenAction, setThenAction] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post(
        'https://example.com/submit-condition-action', // Replace with your API endpoint
        { condition, thenAction },
      );

      if (res.data.success) {
        toast.success('Condition and action submitted successfully!');
        // history.push('/next-step');
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data || 'An error occurred';
      console.log('🚀 ~ handleSubmit ~ errorMessage:', errorMessage);
      toast.error(errorMessage.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 120,
        damping: 10,
        duration: 0.5,
      }}
    >
      <Flex justify="center" align="center" h="100vh" bg="#f6f3fc">
        <Box
          w="100%"
          maxW="2xl"
          px="6"
          py="8"
          bg="white"
          boxShadow="lg"
          rounded="lg"
        >
          <Text fontSize="2xl" align="center">
            Create follow up Messages
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>If</FormLabel>
              <Select
                placeholder="Select condition"
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
                mb="4"
              >
                <option value="client-does-not-respond-in-2-days">
                  Client does not respond in 2 days
                </option>
                <option value="client-does-not-sign-contract-in-7-days">
                  Client does not sign contract in 7 days
                </option>
                <option value="client-cancels-meeting">
                  Client cancels meeting
                </option>
                {/* Add more options as needed */}
              </Select>
            </FormControl>

            <FormControl isRequired mt="4">
              <FormLabel>Then</FormLabel>
              <Input
                placeholder="Enter the action to be taken"
                value={thenAction}
                onChange={(e) => setThenAction(e.target.value)}
              />
            </FormControl>

            <Flex justify="center" mt="6">
              <Button
                variant="brand"
                w="100%"
                colorScheme="blue"
                type="submit"
                isLoading={loading}
                isDisabled={loading}
                loadingText="Please wait..."
                spinner={<Spinner size="sm" />}
              >
                Submit
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default FollowUp;
