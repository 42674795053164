import React, { useEffect, useRef } from 'react';
import './BrandsUsing.css'; // Ensure this path matches the location of your CSS file
import BaekersDozen from '../BrandImages/barkers-dozen.png';
import Bummer from '../BrandImages/bummer.png';
import Dazzo from '../BrandImages/dazzo.png';
import NailIb from '../BrandImages/nailIB.png';
import OrangeAI from '../BrandImages/orange-ain.png';
import RazorPayRise from '../BrandImages/razorpay-rizen.png';
import Salty from '../BrandImages/salty.png';
import WhatMore from '../BrandImages/WhatMore.png';
import SpurFit from '../BrandImages/SpurFit.png';

const BrandUsing = () => {
  const imagesContainerRef = useRef(null);

  useEffect(() => {
    const container = imagesContainerRef.current;
    const originalContent = container.innerHTML;
    container.innerHTML += originalContent; // Duplicate the images

    let scrollAmount = 0;
    const speed = 1; // Adjust speed as needed

    const scrollImages = () => {
      scrollAmount += speed;
      if (scrollAmount >= container.scrollWidth / 2) {
        container.scrollLeft = 0;
        scrollAmount = 0;
      } else {
        container.scrollLeft += speed;
      }

      requestAnimationFrame(scrollImages);
    };

    scrollImages(); // Start the scrolling

    // Cleanup to prevent memory leaks if component unmounts
    return () => {
      cancelAnimationFrame(scrollImages);
    };
  }, []);
  return (
    <div className="partners-collaborating">
      {/* <div className="partner-heading"> */}
      <p className="hero-section-email-elements-para">
        Brands using <span className="hero-section-text-gradient">MailAmp</span>{' '}
      </p>
      {/* </div> */}
      <div className="banner">
        <div className="images" ref={imagesContainerRef}>
          <img src={BaekersDozen} alt="School 1" />
          {/* <span>Checkbox</span> */}

          {/* <img src={Bummer} alt="School 2" /> */}
          {/* <span>Radio</span> */}
          <img src={Dazzo} alt="School 3" />
          {/* <span>Calender</span> */}
          <img src={NailIb} alt="School 5" />
          {/* <span>Rating</span> */}
          <img src={OrangeAI} alt="School 6" />
          {/* <span>ShoppingCart</span> */}
          {/* <img src={RazorPayRise} alt="School 6" /> */}
          <img src={Salty} alt="School 6" />
          <img src={SpurFit} alt="School 6" />
          {/*                     <img src={WhatMore} alt="School 6" /> */}
          <img src={BaekersDozen} alt="School 1" />
          {/* <span>Checkbox</span> */}
          {/* <img src={Bummer} alt="School 2" /> */}
          {/* <span>Radio</span> */}
          <img src={Dazzo} alt="School 3" />
          {/* <span>Calender</span> */}
          <img src={NailIb} alt="School 5" />
          {/* <span>Rating</span> */}
          <img src={OrangeAI} alt="School 6" />
          {/* <span>ShoppingCart</span> */}
          {/* <img src={RazorPayRise} alt="School 6" /> */}
          <img src={Salty} alt="School 6" />
          <img src={SpurFit} alt="School 6" />
          {/*                     <img src={WhatMore} alt="School 6" /> */}
        </div>
      </div>
      {/* <div className="partner-heading">
                <p><span className='banner-text' className="text-wrapper-5">⁠Check</span> <span className='banner-text' className="text-wrapper-7">Students</span> <span className='banner-text'
                        className="text-wrapper-5">Love Us</span> </p>
            </div> */}
      <hr className="horizontal-line-email-elements"></hr>
    </div>
  );
};

export default BrandUsing;
