import React from 'react';
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';

const MessagesCustomModal = ({ isOpen, onClose, onAllow }) => {
  if (!isOpen) return null;

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.5)"
      justify="center"
      align="center"
      zIndex="1000"
    >
      <Box bg="white" p="6" rounded="md" shadow="md" maxW="400px" w="100%">
        <Text mb="4">
          We will now use your WhatsApp business account number to send the
          first message. Click on allow to continue.
        </Text>
        <Flex justify="flex-end">
          <Button variant="ghost" mr={3} onClick={onClose}>
            Deny
          </Button>
          <Button colorScheme="blue" onClick={onAllow}>
            Allow
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default MessagesCustomModal;
