import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Flex,
  Text,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import CustomModal from './CustomModal';
import toast from 'react-hot-toast';

const CreateAgent = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [name, setName] = useState('');
  const [customKnowledge, setCustomKnowledge] = useState('');
  const [tenantQuestions, setTenantQuestions] = useState('');
  const [tenantRequirements, setTenantRequirements] = useState('');
  const [applicationProcess, setApplicationProcess] = useState('');
  const [importantPolicies, setImportantPolicies] = useState('');

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const res = await axios.get(
          'https://sms-app-backend-lwl0.onrender.com/api/v1/scrap/properties',
        );
        console.log('🚀 ~ fetchProperties ~ res:', res);
        setProperties(res.data);
      } catch (error) {
        console.error('Error fetching properties:', error);
        toast.error('Error fetching properties');
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const handleCreateAgentClick = (property) => {
    setSelectedProperty(property);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setSelectedProperty(null);
    setName('');
    setCustomKnowledge('');
    setTenantQuestions('');
    setTenantRequirements('');
    setApplicationProcess('');
    setImportantPolicies('');
    setIsModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    console.log('🚀 ~ handleSubmit ~ agentCreation:', {
      propertyId: selectedProperty._id,
      customKnowledge,
      tenantQuestions,
      tenantRequirements,
      applicationProcess,
      importantPolicies,
    });

    try {
      const res = await axios.post(
        'https://sms-app-backend-lwl0.onrender.com/api/v1/scrap/createproperties',
        {
          id: selectedProperty._id,
          question_for_tenants: tenantQuestions,
          tenant_requirement: tenantRequirements,
          application_process: applicationProcess,
          policies: importantPolicies,
          custom_knowledge: customKnowledge,
          agent_id: selectedProperty._id,
        },
      );
      console.log('🚀 ~ handleSubmit ~ res:', res);

      if (res.status === 200) {
        toast.success('Property created successfully');
        handleClose();
        // Redirect or other actions if necessary
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error creating agent:', error);
      toast.error('Error creating property');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 120,
        damping: 10,
        duration: 0.5,
      }}
    >
      <Flex direction="column" minH="100vh" bg="#f6f3fc">
        <Flex flex="1" justify="center" align="center">
          <Box
            w="100%"
            maxW="2xl"
            px="6"
            py="8"
            bg="white"
            boxShadow="lg"
            rounded="lg"
          >
            <Text fontSize="2xl" mb="4" textAlign="center">
              Your Property List
            </Text>
            {properties.length === 0 ? (
              <Text textAlign="center">Your property list is empty</Text>
            ) : (
              properties.map((property, index) => (
                <Flex key={property.id} align="center" mb="4">
                  <Text>{index + 1 + '.'}</Text>
                  <Text flex="1" ml="4">
                    {property.title}
                  </Text>
                  <Button
                    variant="brand"
                    onClick={() => handleCreateAgentClick(property)}
                  >
                    Create
                  </Button>
                </Flex>
              ))
            )}
          </Box>
        </Flex>

        <CustomModal
          isOpen={isModalOpen}
          onClose={handleClose}
          selectedProperty={selectedProperty}
          handleSubmit={handleSubmit}
          customKnowledge={customKnowledge}
          setCustomKnowledge={setCustomKnowledge}
          tenantQuestions={tenantQuestions}
          setTenantQuestions={setTenantQuestions}
          tenantRequirements={tenantRequirements}
          setTenantRequirements={setTenantRequirements}
          applicationProcess={applicationProcess}
          setApplicationProcess={setApplicationProcess}
          importantPolicies={importantPolicies}
          setImportantPolicies={setImportantPolicies}
          loading={loading}
        />
      </Flex>
    </motion.div>
  );
};

export default CreateAgent;
