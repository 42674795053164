// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import LineChart from 'components/charts/LineChart';
import React from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdBarChart, MdOutlineCalendarToday } from 'react-icons/md';
// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import { lineChartProductEngagementLineChart } from 'variables/charts';
import { lineChartOptionsProductEngagementLineChart } from 'variables/charts';
import { lineChartOptionsEngagementLineChart } from 'variables/charts';
import { lineChartDataEngagementLineChart } from 'variables/charts';
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from 'variables/charts';

export default function ProductEngagementLineChart(props) {
  const { ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%">
          {/* <Button
              bg={boxBg}
              fontSize="sm"
              fontWeight="500"
              color={textColorSecondary}
              borderRadius="7px"
            >
              <Icon
                as={MdOutlineCalendarToday}
                color={textColorSecondary}
                me="4px"
              />
              This month
            </Button> */}
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Engagement Metrics
          </Text>
          <Button
            ms="auto"
            align="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            {...rest}
          >
            <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
          </Button>
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            10,000
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              Total Clicks
            </Text>
            {/* <Flex align="center">
                <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
                <Text color="green.500" fontSize="sm" fontWeight="700">
                  +2.45%
                </Text>
              </Flex> */}
          </Flex>

          <Flex align="center" mb="20px">
            {/* <Icon as={IoCheckmarkCircle} color="green.500" me="4px" /> */}
            <Text color="#56c3ff" fontSize="md" fontWeight="700">
              Property A: 1350/10000
            </Text>
          </Flex>

          <Flex align="center">
            {/* <Icon as={IoCheckmarkCircle} color="green.500" me="4px" /> */}
            <Text color="#5e3aff" fontSize="md" fontWeight="700">
              Property B: 4050/10000
            </Text>
          </Flex>
        </Flex>
        <Box minH="260px" minW="75%" mt="auto">
          <LineChart
            chartData={lineChartProductEngagementLineChart}
            chartOptions={lineChartOptionsProductEngagementLineChart}
          />
          <Text mt="20px" fontSize="lg" fontWeight="bold" color="#57c3ff">
            3x more Engagement
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}
