import React, { useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Input,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Select,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import { SearchIcon, AddIcon } from '@chakra-ui/icons';
import RoundProgressBar from '../default/components/RoundProgressBar';
import ProductEngagementLineChart from './ProductEngagementLineChart';

const Dashboard = () => {
  const properties = [
    {
      address: '1600 Main Street, San Francisco, CA',
      nextTour: '-',
      inquiries: 48,
      tours: 1,
    },
    {
      address: '415 Mission St, San Francisco, CA 94105',
      nextTour: '-',
      inquiries: 4,
      tours: 0,
    },
    {
      address: '2801 Leavenworth St, San Francisco, CA 94133',
      nextTour: '-',
      inquiries: 47,
      tours: 2,
    },
    {
      address: '501 Stanyan StSan Francisco, CA 94117',
      nextTour: '-',
      inquiries: 22,
      tours: 0,
    },
    {
      address: '24 Willie Mays Plaza, San Francisco, CA 94107',
      nextTour: '-',
      inquiries: 11,
      tours: 0,
    },
    {
      address: '1 Warriors Way, San Francisco, CA 94158',
      nextTour: '-',
      inquiries: 5,
      tours: 0,
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProperties = properties.filter((property) =>
    property.address.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3, lg: 3, xl: 3, '2xl': 6 }}
        gap="20px"
        mb="20px"
      >
        <RoundProgressBar
          value={90}
          color="blue.400"
          size={{
            base: '100px',
            sm: '120px',
            md: '150px',
            lg: '150px',
            xl: '150px',
            '2xl': '150px',
          }}
          title="Messages Sent"
          fontWeight="bold"
        />
        <RoundProgressBar
          value={75}
          color="#f68b1f"
          size={{
            base: '100px',
            sm: '120px',
            md: '150px',
            lg: '150px',
            xl: '150px',
            '2xl': '150px',
          }}
          labelsize="16px"
          title="Messages Delivered"
          fontWeight="bold"
        />
        <RoundProgressBar
          value={50}
          color="purple.500"
          size={{
            base: '100px',
            sm: '120px',
            md: '150px',
            lg: '150px',
            xl: '150px',
            '2xl': '150px',
          }}
          labelsize="16px"
          title="Messages Undelivered"
          fontWeight="bold"
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap="20px" mb="20px">
        <ProductEngagementLineChart />
      </SimpleGrid>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem
          w="100%"
          bg="white"
          p={4}
          borderRadius="lg"
          boxShadow="md"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontSize="lg" fontWeight="bold">
            New Leads
          </Text>
          <Text fontSize="3xl">59</Text>
          <Text color="gray.500">Last 48 hours</Text>
        </GridItem>
        <GridItem
          w="100%"
          bg="white"
          p={4}
          borderRadius="lg"
          boxShadow="md"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontSize="lg" fontWeight="bold">
            Messages
          </Text>
          <Text fontSize="3xl">552</Text>
          <Text color="gray.500">Last 48 hours</Text>
        </GridItem>
        <GridItem
          w="100%"
          bg="white"
          p={4}
          borderRadius="lg"
          boxShadow="md"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontSize="lg" fontWeight="bold">
            Most Popular Property
          </Text>
          <Text fontSize="3xl">1600 Main Street</Text>
          <Text color="gray.500">Last 48 hours</Text>
        </GridItem>
        {/* <GridItem
          w="100%"
          bg="white"
          p={4}
          borderRadius="lg"
          boxShadow="md"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton aria-label="Add Property" icon={<AddIcon />} />
        </GridItem> */}
      </Grid>
      <Box mt={5} bg="white" p={4} borderRadius="lg" boxShadow="md">
        <Flex mb={4} justify="center" align="center">
          <Input
            placeholder="Filter properties..."
            maxW="200px"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <IconButton aria-label="Search" icon={<SearchIcon />} />
        </Flex>
        <Table variant="simple">
          <Thead bgColor="#f4f4f4">
            <Tr>
              <Th>Address</Th>
              <Th>Next Tour</Th>
              <Th>Inquiries</Th>
              <Th>Tours</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredProperties.map((property, index) => (
              <Tr key={index}>
                <Td>{property.address}</Td>
                <Td>{property.nextTour}</Td>
                <Td>{property.inquiries}</Td>
                <Td>{property.tours}</Td>
                {/* <Td>
                  <IconButton aria-label="Pin" icon={<AddIcon />} />
                </Td> */}
              </Tr>
            ))}
          </Tbody>
          {/* <Tfoot>
            <Tr>
              <Td colSpan="5">
                <Flex justify="space-between" align="center">
                  <Select w="auto">
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                  </Select>
                  <Flex align="center">
                    <Button>Previous</Button>
                    <Text mx={2}>Page 1 of 2</Text>
                    <Button>Next</Button>
                  </Flex>
                </Flex>
              </Td>
            </Tr>
          </Tfoot> */}
        </Table>
      </Box>
    </Box>
  );
};

export default Dashboard;
