import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Spinner,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

const PropertyListingsForm = () => {
  const [url, setUrl] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleTransactionTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    // formData.append('property_link', url);
    // formData.append('property_type', propertyType);

    try {
      const res = await axios.post(
        'https://sms-app-backend-lwl0.onrender.com/api/v1/scrap/scrapapi',
        { property_link: url, property_type: propertyType, email: email },
      );
      console.log('🚀 ~ handleSubmit ~ res:', res);

      if (res.status === 200) {
        toast.success('URL submitted successfully!');
        history.push('/admin/send-messages');
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data || 'An error occurred';
      console.log('🚀 ~ handleSubmit ~ errorMessage:', errorMessage);
      toast.error(errorMessage.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 120,
        damping: 10,
        duration: 0.5,
      }}
    >
      <Flex justify="center" align="center" h="100vh" bg="#f6f3fc">
        <Box
          w="100%"
          maxW="2xl"
          px="6"
          py="8"
          bg="white"
          boxShadow="lg"
          rounded="lg"
        >
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>
                Please paste your property listing (e.g., Zillow,
                Apartments.com)
              </FormLabel>
              <Input
                placeholder="Property URL"
                type="url"
                value={url}
                onChange={handleUrlChange}
                mb="4"
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Property Type</FormLabel>
              <Select
                placeholder="Select property type"
                value={propertyType}
                onChange={handleTransactionTypeChange}
              >
                <option value="Sale">Sale</option>
                <option value="Rent">Rent</option>
              </Select>
            </FormControl>
            <Flex justify="center" mt="6">
              <Button
                variant="brand"
                w="100%"
                colorScheme="blue"
                type="submit"
                isLoading={loading}
                isDisabled={loading}
                loadingText="Please wait..."
                spinner={<Spinner size="sm" />}
              >
                Next
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default PropertyListingsForm;
