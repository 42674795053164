import React, { useEffect } from 'react';
import 'assets/css/App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { Toaster } from 'react-hot-toast';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import CampaignDetails from 'views/CampaignDashboard/CampaignDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./index.css"
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-HV9LEP4QGW');
const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
return(
<GoogleOAuthProvider clientId="190854580021-jamhsk4t0q546hqpgi9g869ogm4376ha.apps.googleusercontent.com">
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <Router>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/rtl`} component={RtlLayout} />
            <Route path={"/campaign-details/:campaign_id"} component={CampaignDetails}/>
            <Redirect from="/" to="/admin" />
          </Switch>
        </Router>
        <Toaster position="top-right" />
      </ThemeEditorProvider>
    </React.StrictMode>
    <ToastContainer
       position="top-right"
       autoClose={1000}
       hideProgressBar={true}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       theme="colored"
    />
  </ChakraProvider>
  </GoogleOAuthProvider>
);

};

root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
