import React from 'react';
import './HeroSection.css';
import Graph from './Graph/Graph';

function HeroSection() {
  const handleBookADemo = (event) => {
    event.preventDefault();
    window.open('https://calendly.com/ressi-54/30min?back=1', '_blank');
  };
  return (
    <div className="hero-section">
      <div className="cloud-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="507"
          height="884"
          viewBox="0 0 507 884"
          fill="none"
        >
          <g opacity="0.7" filter="url(#filter0_f_910_2178)">
            <circle
              cx="65"
              cy="442"
              r="141"
              fill="url(#paint0_linear_910_2178)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_910_2178"
              x="-376.95"
              y="0.0499878"
              width="883.9"
              height="883.9"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="150.475"
                result="effect1_foregroundBlur_910_2178"
              />
            </filter>
            <linearGradient
              id="paint0_linear_910_2178"
              x1="181.325"
              y1="318.336"
              x2="-71.7854"
              y2="573.315"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4AAEFF" />
              <stop offset="1" stopColor="#2067FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="hero-content">
        <h1>
          <span className="hero-section-text-gradient">
            Increase your revenue
          </span>{' '}
          with AI-powered Whatsapp journeys
        </h1>
        <p>
          Combine WhatsApp with the power of Level-3 AI to lower CAC, amplify
          retention, and deliver exceptional support for your brand.
        </p>
        <button className="hero-button-demo-button" onClick={handleBookADemo}>
          <span>Book a Demo</span>
          <div className="hero-button-arrow-icons-container">
            <span className="hero-button-arrow-icon">
              <svg
                className="hero-button-arrow-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 20 21"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
                aria-hidden="true"
                role="img"
              >
                <path
                  d="M14.3828 7.82861L17.5078 10.9536M17.5078 10.9536L14.3828 14.0786M17.5078 10.9536L2.50781 10.9536"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
          </div>
        </button>
      </div>
      <div className="cloud-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="507"
          height="884"
          viewBox="0 0 507 884"
          fill="none"
        >
          <g opacity="0.7" filter="url(#filter0_f_910_2178)">
            <circle
              cx="442"
              cy="442"
              r="141"
              fill="url(#paint0_linear_910_2178)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_910_2178"
              x="-376.95"
              y="0.0499878"
              width="883.9"
              height="883.9"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="150.475"
                result="effect1_foregroundBlur_910_2178"
              />
            </filter>
            <linearGradient
              id="paint0_linear_910_2178"
              x1="181.325"
              y1="318.336"
              x2="-71.7854"
              y2="573.315"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4AAEFF" />
              <stop offset="1" stopColor="#2067FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default HeroSection;
