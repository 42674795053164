import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Spinner,
  VStack,
  HStack,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import { motion } from 'framer-motion';

const Todos = () => {
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTodos = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          'https://sms-app-backend-lwl0.onrender.com/api/v1/agent/todo',
        ); // Replace with your API endpoint
        console.log('🚀 ~ fetchTodos ~ response:', response);
        setTodos(response.data.data);
      } catch (err) {
        setError('Failed to fetch todos');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTodos();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" h="100vh" bg="#f6f3fc">
        <Text>{error}</Text>
      </Flex>
    );
  }

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 120,
        damping: 10,
        duration: 0.5,
      }}
    >
      <Flex justify="center" align="center" h="100vh" bg="#f6f3fc">
        <Box
          w="100%"
          maxW="2xl"
          px="6"
          py="8"
          bg="white"
          boxShadow="lg"
          rounded="lg"
        >
          <Text fontSize="2xl" align="center" mb="6">
            Todos
          </Text>
          <VStack spacing="4" align="stretch">
            {todos.map((todo, index) => (
              <HStack
                key={todo.id}
                p="4"
                bg="gray.100"
                rounded="md"
                justify="space-between"
                boxShadow="md"
              >
                <Text>
                  {index + 1}. {todo.summary}
                </Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default Todos;
