import axios from 'axios';
import toast from 'react-hot-toast';
const URL = 'https://sms-app-backend-lwl0.onrender.com/api/v1';

export async function signUp(email, password) {
  try {
    const res = await axios.post(`${URL}/login/signup`, { email, password });
    console.log(res);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
}
export async function signIn(email, password) {
  try {
    const res = await axios.post(`${URL}/login/signin`, { email, password });
    console.log(res);
    return res.data;
  } catch (error) {
    console.log(error);
    toast.error('Sign-in failed. Please check your credentials.');
  }
}
