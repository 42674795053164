// RoundProgressBar.js
import React from 'react';
import { motion } from 'framer-motion';
import {
  CircularProgress,
  CircularProgressLabel,
  Box,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';

const RoundProgressBar = (props) => {
  const { value, size, thickness, color, labelsize, title, ...rest } = props;

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Box textAlign="center">
        <motion.div
          initial={{ scale: 0 }} 
          animate={{ scale: [0, 1.3, 1] }} 
          transition={{ duration: 0.5, ease: 'easeInOut' }} 
        >
          <CircularProgress
            value={value}
            size={size}
            thickness={thickness}
            color={color}
            capIsRound
          >
            <CircularProgressLabel fontSize="16px">
              {value}%
            </CircularProgressLabel>
          </CircularProgress>
        </motion.div>
        <Text mt={2}>{title}</Text>
      </Box>
    </Card>
  );
};

export default RoundProgressBar;
