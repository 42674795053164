import React, { useEffect } from 'react'
import RoundProgressBar from 'views/admin/default/components/RoundProgressBar'
import { SimpleGrid,Box,Spinner } from '@chakra-ui/react'
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import "./CampaignDashboard.css"
function CampaignDetails() {
    const [campaignDetails, setCampaignDetails] = useState([]);
    const [loading,setLoading]=useState(true);
    const [openPercentage, setOpenPercentage] = useState(0);
    const [submissionPercentage, setSubmissionPercentage] = useState(0);
    const [engagementPercentage, setEngagementPercentage] = useState(0);
    const { campaign_id } = useParams();
    useEffect(() => {
        fetchCampaignDetails();
    }, [])
    const fetchCampaignDetails = async () => {
        const campaigndetails = await axios.get(`https://bounce.mailamp.in/razerpay/campaign-details?campaignId=${campaign_id}`);
        const campaignoveralldetails = await axios.get(`https://bounce.mailamp.in/razerpay/campaign-overall-details?campaignId=${campaign_id}`);
        console.log(campaigndetails);
        setOpenPercentage(campaignoveralldetails.data.openRate);
        setSubmissionPercentage(campaignoveralldetails.data.clickRate);
        setEngagementPercentage(campaignoveralldetails.data.submissionRate)
        setCampaignDetails(campaigndetails.data.emailDetails);
        setLoading(false);
    };
    if(loading){
        return (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Spinner size="xl" />
          </Box>
        ); 
      }
    return (
        <div className="CampaignDashboard">
            <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 3, xl: 3, '2xl': 6 }}
                gap="20px"
                mb="20px"
            >
                <RoundProgressBar
                    value={openPercentage} // unique value
                    color="blue.400" // unique color
                    size={{
                        base: '100px',
                        sm: '120px',
                        md: '150px',
                        lg: '150px',
                        xl: '150px',
                        '2xl': '150px',
                    }}
                    title="Open"
                    fontWeight="bold"
                />
                <RoundProgressBar
                    value={submissionPercentage} // unique value
                    color="#f68b1f" // unique color
                    size={{
                        base: '100px',
                        sm: '120px',
                        md: '150px',
                        lg: '150px',
                        xl: '150px',
                        '2xl': '150px',
                    }}
                    title="Submission"
                    fontWeight="bold"
                />
                <RoundProgressBar
                    value={engagementPercentage} // unique value
                    color="green.400" // unique color
                    size={{
                        base: '100px',
                        sm: '120px',
                        md: '150px',
                        lg: '150px',
                        xl: '150px',
                        '2xl': '150px',
                    }}
                    title="Engagement"
                    fontWeight="bold"
                />
            </SimpleGrid>
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Open</th>
                        <th>Engagement</th>
                        <th>Submission</th>
                        <th>Ratings</th>
                    </tr>
                </thead>
                <tbody>
                    {campaignDetails?.map(detail => (
                        <tr key={detail.engagement._id}>
                            <td data-label="Email">{detail.email}</td>
                            <td data-label="Open">{detail.engagement.open}</td>
                            <td data-label="Engagement">{detail.engagement.engagement}</td>
                            <td data-label="Submission">{detail.engagement.submission}</td>
                            <td data-label="Ratings" id="last-td">{detail.ratings.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CampaignDetails
