import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  VStack,
  Spinner,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import MessagesCustomModal from './MessagesCustomModal';

const ChatInbox = () => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [previousMessageCount, setPreviousMessageCount] = useState(0);
  const [isUserScrolledUp, setIsUserScrolledUp] = useState(false);

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    let interval;
    if (selectedContact) {
      interval = setInterval(fetchMessages, 3000);
    }
    return () => clearInterval(interval);
  }, [selectedContact]);

  const fetchContacts = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        'https://sms-app-backend-lwl0.onrender.com/api/v1/agent/contacts',
      );
      const data = await response.json();
      const formattedContacts = data.data.map((contact) => ({
        chatgpt_thread: contact.chatgpt_thread,
        name: contact.name || contact.wa_id.toString(),
        wa_id: contact.wa_id,
        realtor_id: contact.realtor_id,
      }));
      setContacts(formattedContacts);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async () => {
    if (!selectedContact) return;

    try {
      const response = await fetch(
        `https://sms-app-backend-lwl0.onrender.com/api/v1/agent/get/chats/messages/${selectedContact.realtor_id}/${selectedContact.wa_id}`,
      );
      const data = await response.json();
      const formattedMessages = data.data[0].messages.map((msg) => ({
        id: msg._id,
        text: msg.message,
        sender:
          msg.from === selectedContact.wa_id.toString() ? 'user' : 'reply',
      }));

      const userScrolledUp =
        chatBoxRef.current.scrollHeight - chatBoxRef.current.scrollTop >
        chatBoxRef.current.clientHeight + 100;

      setMessages((prevMessages) => {
        if (prevMessages.length !== formattedMessages.length) {
          if (!userScrolledUp) {
            setTimeout(scrollToBottom, 100);
          }
        }
        return formattedMessages;
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async (message) => {
    if (message.trim() === '') return;

    const newMessage = {
      id: messages.length + 1,
      text: message,
      sender: 'user',
    };
    setMessages([...messages, newMessage]);
    setMessageInput('');

    try {
      await fetch(
        `https://sms-app-backend-lwl0.onrender.com/api/v1/agent/send/messages`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            wa_id: selectedContact.wa_id,
            message: message,
            realtor_id: selectedContact.realtor_id,
          }),
        },
      );
      scrollToBottom();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleFirstMessage = (contact) => {
    setSelectedContact(contact);
    setMessages([]); // Clear previous messages
    setIsModalOpen(true);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScroll = () => {
    const userScrolledUp =
      chatBoxRef.current.scrollHeight - chatBoxRef.current.scrollTop >
      chatBoxRef.current.clientHeight + 100;
    setIsUserScrolledUp(userScrolledUp);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAllow = () => {
    setIsModalOpen(false);
    handleSendMessage('');
  };

  return (
    <Flex
      direction="column"
      h="100vh"
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Grid templateColumns="1fr 4fr" h="100%">
        <GridItem
          h="100%"
          p="4"
          bg="white"
          overflowY="auto"
          borderRight="1px solid #e2e8f0"
        >
          <Text fontSize="2xl" mb="4">
            Contacts
          </Text>
          {loading ? (
            <Flex justify="center" align="center">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <VStack spacing="4" align="stretch">
              {contacts.map((contact) => (
                <Flex
                  key={contact.chatgpt_thread}
                  align="center"
                  justify="space-between"
                >
                  <Box
                    display="inline-block"
                    bg="blue.100"
                    p="2"
                    rounded="full"
                    px="4"
                    cursor="pointer"
                    onClick={() => handleFirstMessage(contact)}
                  >
                    {contact.name}
                  </Box>
                </Flex>
              ))}
            </VStack>
          )}
        </GridItem>
        <GridItem
          ref={chatBoxRef}
          h="100%"
          bg="gray.100"
          p="4"
          display="flex"
          flexDirection="column"
          overflowY="auto"
          onScroll={handleScroll}
        >
          <Text fontSize="2xl" mb="4">
            Chat with{' '}
            {selectedContact ? (
              <Box
                display="inline-block"
                bg="blue.100"
                p="2"
                rounded="full"
                px="4"
              >
                {selectedContact.name}
              </Box>
            ) : (
              '...'
            )}
          </Text>
          <Box bg="white" p="4" rounded="md" flex="1" overflowY="auto">
            {messages.length === 0 ? (
              <Flex justify="center" align="center" h="100%">
                <Text fontSize="xl">There are no messages</Text>
              </Flex>
            ) : (
              messages.map((message) => (
                <Flex
                  key={message.id}
                  mb="2"
                  justifyContent={
                    message.sender === 'user' ? 'flex-end' : 'flex-start'
                  }
                >
                  <Box
                    p="2"
                    color="black"
                    bg={message.sender === 'user' ? 'blue.100' : '#f0f0f0'}
                    rounded="lg"
                    borderRadius={
                      message.sender === 'user'
                        ? '15px 15px 0 15px'
                        : '15px 15px 15px 0'
                    }
                    maxW="50%"
                  >
                    {message.text}
                  </Box>
                </Flex>
              ))
            )}
            <div ref={messagesEndRef} />
          </Box>
          {selectedContact && (
            <Flex
              mt="4"
              as="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSendMessage(messageInput);
              }}
            >
              <FormControl mr="4" flex="1">
                <Input
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  placeholder="Type your message..."
                  autoComplete="off"
                />
              </FormControl>
              <Button type="submit" colorScheme="blue">
                Send
              </Button>
            </Flex>
          )}
        </GridItem>
      </Grid>

      <MessagesCustomModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onAllow={handleAllow}
      />
    </Flex>
  );
};

export default ChatInbox;
