import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';

const CustomModal = ({
  isOpen,
  onClose,
  selectedProperty,
  handleSubmit,
  customKnowledge,
  setCustomKnowledge,
  tenantQuestions,
  setTenantQuestions,
  tenantRequirements,
  setTenantRequirements,
  applicationProcess,
  setApplicationProcess,
  importantPolicies,
  setImportantPolicies,
  loading,
}) => {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      w="100%"
      h="100%"
      bg="rgba(0, 0, 0, 0.5)"
      zIndex="1000"
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={handleBackgroundClick}
    >
      <Box
        bg="white"
        w="90%"
        maxW="500px"
        p="6"
        boxShadow="lg"
        rounded="md"
        maxHeight="80vh"
        overflowY="auto"
        onClick={(e) => e.stopPropagation()}
      >
        <Text fontSize="2xl" mb="4">
          Custom Knowledge for {selectedProperty?.name}
        </Text>
        <form onSubmit={handleSubmit}>
          {/* <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter agent name"
            />
          </FormControl> */}
          <FormControl mt="4" isRequired>
            <FormLabel>Custom Knowledge</FormLabel>
            <Textarea
              value={customKnowledge}
              onChange={(e) => setCustomKnowledge(e.target.value)}
              placeholder="Enter custom knowledge"
              h="100px"
              resize="vertical"
            />
          </FormControl>
          <Text mt="4" fontSize="xl">
            Recommended <span style={{ color: '#ee6550' }}>*</span>
          </Text>
          <FormControl mt="4">
            <FormLabel>
              What questions would you like your AI to ask prospective tenants?
              (recommended)
            </FormLabel>
            <Textarea
              value={tenantQuestions}
              onChange={(e) => setTenantQuestions(e.target.value)}
              placeholder="Number of tenants moving in, desired move-in date, if they have any pets"
              h="100px"
              resize="vertical"
            />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>
              What are your tenant requirements? (recommended)
            </FormLabel>
            <Textarea
              value={tenantRequirements}
              onChange={(e) => setTenantRequirements(e.target.value)}
              placeholder="We generally look for income 2x rent, proof of employment, good credit but can make exceptions on occasion (co-signers, etc.)"
              h="100px"
              resize="vertical"
            />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>
              What is your application process? (recommended)
            </FormLabel>
            <Textarea
              value={applicationProcess}
              onChange={(e) => setApplicationProcess(e.target.value)}
              placeholder="Apply on our website, $30 application fee (covers the background check). To apply you must submit your contact info, income information, official documents, etc."
              h="100px"
              resize="vertical"
            />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>Important Policies (optional)</FormLabel>
            <Textarea
              value={importantPolicies}
              onChange={(e) => setImportantPolicies(e.target.value)}
              placeholder="Desired move-in date must be within 30 days to schedule a tour"
              h="100px"
              resize="vertical"
            />
          </FormControl>
          <Flex mt="4" justify="flex-end">
            <Button onClick={onClose} mr="3">
              Cancel
            </Button>
            <Button variant="brand" type="submit" isLoading={loading}>
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default CustomModal;
