import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import './Graph.css';
// Register Chart.js components for both Bar and Line charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
);

function Graph() {
  // Data for the first bar chart
  const barData = {
    labels: ['1', '2', '3', '4', '5'],
    datasets: [
      {
        data: [10, 16, 20, 30, 40], // Heights of the bars
        backgroundColor: [
          'rgb(0, 255,0)',
          'rgb(2, 223,2)',
          'rgb(23, 195, 23)',
          'rgb(67, 194, 67)',
          'rgb(1, 126, 1)',
        ],
        borderWidth: 1,
        barThickness: 20,
        borderColor: [
          'rgb(0, 255,0)',
          'rgb(2, 223,2)',
          'rgb(91, 195, 91)',
          'rgb(51, 194, 51)',
          'rgb(1, 126, 1)',
        ],
        borderRadius: 5,
      },
    ],
  };

  // Data for the second line chart with increasing trend
  const lineData = {
    labels: ['1', '2', '3', '4', '5'], // Labels for the x-axis
    datasets: [
      {
        label: 'Conversion Rate',
        data: [10, 30, 15, 40, 60], // Increasing heights
        borderColor: '#3e4ce1', // Color for the line
        backgroundColor: '#3e4ce1', // Background color for the area under the line
        borderWidth: 2, // Line thickness
        pointRadius: 3, // Size of points on the line
        fill: true,
      },
    ],
  };
  const bubbleLineData = {
    labels: ['1', '2', '3', '4', '5'], // Labels for the x-axis
    datasets: [
      {
        label: 'Bubble Line',
        data: [
          { x: '1', y: 0 }, // Adjusted y-values to reduce overlapping
          { x: '2', y: 0 },
          { x: '2', y: 20 }, // Adjusting vertical gaps
          { x: '3', y: 0 },
          { x: '3', y: 20 },
          { x: '3', y: 40 },
          { x: '4', y: 0 },
          { x: '4', y: 20 },
          { x: '4', y: 40 },
          { x: '4', y: 60 },
        ],
        borderColor: [
          '##fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
        ],
        // Border color of the dataset
        backgroundColor: 'transparent', // Background of the line
        borderWidth: 4, // Line width
        pointRadius: 6, // Radius of the points
        pointBorderColor: [
          '#dcd1ae',
          '#ac9f74',
          '#ac9f74',
          '#c0a95f',
          '#c0a95f',
          '#c0a95f',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
          '#fcce3d',
        ], // Border color of the points
        pointBackgroundColor: 'transparent', // Transparent background for points to create the circle effect
        showLine: false,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        display: false, // Hide x-axis grid lines and labels
      },
      y: {
        display: false, // Hide y-axis grid lines and labels
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  return (
    <>
      <div className="graph-heading-container">
        <h1>
          There is a reason why your open rates and click though rates are low."{' '}
        </h1>
        {/* <h4>
          There is a reason why your open rates and click though rates are low.
        </h4> */}
      </div>
      <div className="graph-container">
        <div className="graph-content">
          <div className="upper-content">
            <div>Up to</div>
            <div>3x</div>
          </div>
          <div className="lower-content">
            <div> Increase in revenue</div>
            <div className="my-graph">
              <Bar data={barData} options={options} />
            </div>
          </div>
        </div>
        <div className="graph-content">
          <div className="upper-content">
            <div>Up to</div>
            <div style={{ color: '#3e4ce1' }}>307%</div>
          </div>
          <div className="lower-content">
            <div>Conversion increase</div>
            <div className="my-graph">
              <Line data={lineData} options={options} />
            </div>
          </div>
        </div>
        <div className="graph-content">
          <div className="upper-content">
            <div>Up to</div>
            <div style={{ color: '#fcce3d' }}>4x</div>
          </div>
          <div className="lower-content">
            <div
              style={{ marginRight: '30px', width: '45%', paddingLeft: '10px' }}
            >
              Increase in engagement rate
            </div>
            <div className="my-graph">
              <Line data={bubbleLineData} options={options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Graph;
