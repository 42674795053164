import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Button, Flex } from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';

const NewFaceBookLoginPage = () => {
  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '891123312823233',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v20.0',
      });
    };

    // Append the SDK script to the document
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // Set up the MessageEvent listener
    window.addEventListener('message', (event) => {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id, email } = data.data;
            console.log(
              'Phone number ID ',
              phone_number_id,
              ' WhatsApp business account ID ',
              waba_id,
            );
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn('Cancel at ', current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error('error ', error_message);
          }
        }
        document.getElementById('session-info-response').textContent =
          JSON.stringify(data, null, 2);
      } catch {
        console.log('Non JSON Responses', event.data);
      }
    });
  }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      // The returned code must be transmitted to your backend first and then
      // perform a server-to-server call from there to our servers for an access token.
    }
    document.getElementById('sdk-response').textContent = JSON.stringify(
      response,
      null,
      2,
    );
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: '1726359341505579', // configuration ID goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      },
    });
  };

  return (
    <Button
      leftIcon={<FaFacebook />}
      colorScheme="facebook"
      onClick={launchWhatsAppSignup}
      width="50%"
    >
      Login with Facebook
    </Button>
  );
};

export default NewFaceBookLoginPage;
