import React from 'react';
import { motion } from 'framer-motion';
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import NewFaceBookLoginPage from '../facebook-login/NewFaceBookLoginPage';

const FacebookPage = () => {
  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 120,
        damping: 10,
        duration: 0.5,
      }}
    >
      <Flex justify="center" align="center" h="100vh" bg="#f6f3fc">
        <Box
          w="100%"
          maxW="2xl"
          px="6"
          py="8"
          bg="white"
          boxShadow="lg"
          rounded="lg"
          justify="center"
          align="center"
        >
          <Text fontSize="2xl" mb="4" textAlign="center">
            Connect Your Facebook Account
          </Text>
          <NewFaceBookLoginPage />
        </Box>
      </Flex>
    </motion.div>
  );
};

export default FacebookPage;
