import React from 'react';

// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import { MailAmpIcon } from 'components/icons/Icons';

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  const handleImageClick = (event) => {
    event.preventDefault();
    window.open('https://www.mailamp.in/', '_blank');
  };

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h="26px" w="175px" my="32px" color={logoColor} /> */}
      {/* <MailAmpIcon h="26px" w="175px" my="32px" color={logoColor} /> */}
      <img
        onClick={handleImageClick}
        src="/pixolabs-logo.png"
        alt="brand-logo"
        style={{
          height: '50px',
          width: '136px',
          marginBottom: '32px ',
          objectFit: 'contain',
          cursor: 'pointer',
        }}
      />

      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
