import React from 'react';
import HeroSection from './Components/HeroSection/HeroSection';
import Features from './Components/Features/Features';
import Navbar from './Components/Navbar/Navbar';
import Footer from 'components/footer/FooterAdmin';
import Graph from './Components/HeroSection/Graph/Graph';
import BrandUsing from './Components/HeroSection/BrandsUsing/BrandsUsing';
import frame1 from 'assets/img/LandingPage/frame1.png';
import frame2 from 'assets/img/LandingPage/frame2.png';
import frame3 from 'assets/img/LandingPage/frame3.png';
function LandingPage() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Graph />
      <BrandUsing />
      <Features
        subtitle={'GPT-Powered support'}
        imgSrc={frame1}
        title="Automate all customer queries"
        desc="
Transform your e-commerce support with the help of AI, resolving 90% of inquiries instantly. Turn support from a cost center into a revenue generator with advanced Level-3 AI. Experience rapid, scalable solutions that deliver tangible results."
      />
      <Features
        subtitle={'Commerce Journey'}
        imgSrc={frame2}
        title={'Capture the real CSAT'}
        desc={
          'Capture users who drop off from QR codes, ads, and website product pages and redirect them to WhatsApp. Simplify their buying process with a seamless WhatsApp commerce journey. Gather first-party data and nurture these users until they are ready to make a purchase.'
        }
      />
      <Features
        subtitle={'Gamification'}
        imgSrc={frame3}
        title={'Improve CTR with gamification'}
        desc={
          'Capture users who drop off from QR codes, ads, and website product pages and guide them to WhatsApp. Simplify their purchasing experience with a seamless WhatsApp commerce journey. Gather first-party data and nurture these users until they are ready to buy.'
        }
      />
      <Footer />
    </>
  );
}

export default LandingPage;
